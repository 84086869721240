<template>
	<div class="page-sub-box ewm">
		<esp-dx-data-grid :data-grid="dataGrid" ref="evaluationSheetGrid" @init-new-row="onInitNewRow" @saving="e => onSave(e)"> </esp-dx-data-grid>
	</div>
</template>

<script>
import { DxSwitch } from 'devextreme-vue/switch';
import { isSuccess } from '@/plugins/common-lib';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";


let vm = this;

export default {
	components: {EspDxDataGrid},
	data() {
		return {
			codeMap: {},
			dataGridLoopup: {
				evalDivisionParentCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				evalDivisionCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {},
				paramsData: {},
			},
			dataGrid: {
				keyExpr: 'id',
				refName: 'evaluationSheetGrid',
				allowColumnResizing: true, //컬럼 사이즈 허용
				columnResizingMode: 'widget',
				columnAutoWidth: true,
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				dataSource: [],
				scrolling: {
					mode: 'standard',
				},
				callApi: 'CALL_EWM_API',
				apiActionNm: {},
				customEvent: {
					//그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
					cellHoverChanged: false,
					cellPrepared: false,
					checkDataBeforeSaving: false,
					initNewRow: true,
					rowInserted: false,
					rowInserting: false,
					saving: true,
				},
				showActionButtons: {
					copy: false,
					select: true,
					update: false,
					delete: false,
					excel: false,
					customButtons: [
						{
							widget: 'dxButton',
							sortIndex: 20,
							options: {
								icon: '',
								text: '복사생성',
								elementAttr: { class: 'btn_XS default filled' },
								height: 30,
								onClick: function () {
									vm.onCopy();
								},
							},
							location: 'before',
						},
						{
							widget: 'dxButton',
							options: {
								icon: '',
								text: '삭제',
								elementAttr: { class: 'btn_XS white light_filled trash' },
								width: 60,
								height: 30,
								onClick() {
									vm.onDeleteData();
								},
							},
							location: 'before',
						},
					],
				},
				grouping: {
					contextMenuEnabled: false,
					autoExpandAll: false,
					allowCollapsing: true,
					expandMode: 'rowClick', // rowClick or buttonClick
				},
				groupPanel: {
					visible: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: true, // 로딩바 표시 유무
				},
				sorting: {
					mode: 'multiple', // single multiple
				},
				remoteOperations: {
					// 서버사이드 여부
					filtering: false,
					sorting: false,
					grouping: false,
					paging: false,
				},
				paging: {
					enabled: false,
				},
				pager: {
					visible: false, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [],
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: true,
				},
				headerFilter: {
					visible: true,
				},
				editing: {
					allowUpdating: options => {
						if (options.row.rowType === 'data' && options.row.data.useFl === this.$_enums.common.stringUsedFlag.YES.value) {
							return false;
						} else {
							return true;
						}
					}, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
					allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
					allowDeleting: false,
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
					mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
					selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
					showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
				},
				columns: [
					{
						caption: '평가구분',
						dataField: 'evalDivisionParentCd',
						width: 200,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: true,
						allowGrouping: false,
						lookup: {},
						requiredRule: {
							message: '필수 항목입니다.',
						},
						calculateSortValue: data => vm.calculateSortValue(data, 'evalDivisionParentCd'),
						setCellValue: (newData, value, currentRowData) => {
							newData.evalDivisionParentCd = value;
							newData.evalDivisionCd = null;
						},
					},
					{
						caption: '평가구분상세',
						dataField: 'evalDivisionCd',
						width: 220,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: true,
						allowGrouping: false,
						lookup: {},
						requiredRule: {
							message: '필수 항목입니다.',
						},
						calculateSortValue: data => vm.calculateSortValue(data, 'evalDivisionCd'),
					},
					{
						caption: '평가표명',
						dataField: 'sheetNm',
						height: 40,
						visible: true,
						allowEditing: true,
						sortOrder: 'none', // acs desc none
						allowHeaderFiltering: false,
						allowGrouping: false,
						cellTemplate: (container, options) => {
							let aTag = document.createElement('a');
							aTag.innerText = options.value;
							aTag.addEventListener('click', () => {
								vm.onUpdateData(options.data);
							});
							container.append(aTag);
						},
						requiredRule: {
							message: '필수 항목입니다.',
						},
					},
					{
						caption: '배점',
						dataField: 'sheetScore',
						width: 150,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: true,
						allowGrouping: false,
					},
					{
						caption: '사용여부',
						dataField: 'viewFl',
						width: 130,
						height: 40,
						alignment: 'center', // left center right
						visible: true,
						allowEditing: false,
						sortOrder: 'none', // acs desc none
						allowHeaderFiltering: false,
						calculateSortValue: data => {
							return this.$_enums.common.stringUsedFlag.values.find(e => data.viewFl == e.value).label;
						},
						cellTemplate: (container, options) => {
							const switchBtn = new DxSwitch({
								propsData: {
									value: options.data.viewFl === this.$_enums.common.stringUsedFlag.YES.value,
									disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? true : false,
									//readOnly: true,
									onValueChanged: e => {
										const viewFlVal = e.value
											? this.$_enums.common.stringUsedFlag.YES.value
											: this.$_enums.common.stringUsedFlag.NO.value;
										this.$refs.evaluationSheetGrid.getGridInstance.cellValue(options.rowIndex, 'viewFl', viewFlVal);
									},
								},
							});
							switchBtn.$mount();
							container.append(switchBtn.$el);
						},
						/* editCellTemplate: (container, options) => {
							const switchBtn = new DxSwitch({
								propsData: {
									value: options.data.viewFl === this.$_enums.common.stringUsedFlag.YES.value,
									disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? true : false,
									onValueChanged: e => {
										const viewFlVal = e.value
											? this.$_enums.common.stringUsedFlag.YES.value
											: this.$_enums.common.stringUsedFlag.NO.value;
										options.setValue(viewFlVal);
									},
								},
							});
							switchBtn.$mount();
							container.append(switchBtn.$el);
						}, */
						lookup: {
							dataSource: this.$_enums.common.stringUsedFlag.values,
							displayExpr: 'label',
							valueExpr: 'value',
						},
						requiredRule: {
							message: '필수 항목입니다.',
						},
					},
					{
						caption: '평가이력',
						dataField: 'useFl',
						width: 130,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						lookup: {
							dataSource: this.$_enums.common.stringUsedFlag.values,
							displayExpr: 'label',
							valueExpr: 'value',
						},
						calculateSortValue: data => {
							return this.$_enums.common.stringUsedFlag.values.find(e => data.useFl == e.value).label;
						},
					},
				],
			},
		};
	},
	computed: {},
	methods: {
		/** @description : 소트설정 */
		calculateSortValue(data, clsCd) {
			if (clsCd == 'evalDivisionParentCd') {
				return this.dataGridLoopup.evalDivisionParentCd.dataSource.find(e => data.evalDivisionParentCd == e.codeId).codeNm;
			} else if (clsCd == 'evalDivisionCd') {
				const evalDivisionCdDataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 3);

				return evalDivisionCdDataSource.find(e => data.evalDivisionCd == e.codeId).codeNm;
			}
		},
		/** @description : 복사생성 */
		async onCopy() {
			const selectedRowsData = this.$refs.evaluationSheetGrid.selectedRowsData;

			if (selectedRowsData.length < 1) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DUPLICATE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

			const sendData = selectedRowsData.map(x => x.id);

			const payload = {
				actionname: 'EWM_EVALUATION_SHEET_DUPLICATE',
				data: sendData,
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				this.$refs.evaluationSheetGrid.getGridInstance.refresh();
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 추가 */
		onAdd() {
			this.$router.push('/ewm/evaluation/setting/sheet/register');
			//this.$router.push({ path: '/ewm/evaluation/setting/sheet/register' });
		},
		/** @description : 데이터 추가 시 기본 값 적용하기 위함 */
		onInitNewRow(e) {
			e.data.sheetNm = '';
			e.data.viewFl = this.$_enums.common.stringUsedFlag.YES.value;
			e.data.useFl = this.$_enums.common.stringUsedFlag.NO.value;
			e.data.delFl = this.$_enums.common.stringUsedFlag.NO.value;
			e.sheetScore = 0;
		},
		/** @description : 저장 */
		async onSave(e) {
			e.cancel = true;

			if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), { title: `<h2>알림</h2>` }))) {
				return;
			}

			if (e.changes.length) {
				let data = [];

				if (e.changes && e.changes.length > 0) {
					e.changes.forEach(d => {
						this.dataGrid.keyExpr ? this.dataGrid.keyExpr : 'id';

						if (d.type === 'update') {
							d.data.editId = this.$store.getters.getLoginId; //수정자

							if (this.dataGrid.keyExpr) {
								let rowIndex = e.component.getRowIndexByKey(d.key);
								let row = e.component.getVisibleRows()[rowIndex];
								let updateData = row.data;
								//updateData['fileId'] = updateData['file']?.id;

								/* let updateData = { ...d.data };
								updateData[this.dataGrid.keyExpr] = d.key; */
								data.push(updateData);
							} else {
								data.push({ id: d.key, ...d.data });
							}
						} else if (d.type === 'insert') {
							if (Object.keys(d.data).length !== 0) {
								d.data.regId = this.$store.getters.getLoginId; //등록자
								if (this.dataGrid.keyExpr) {
									let updateData = { ...d.data };
									updateData[this.dataGrid.keyExpr] = null;
									//updateData['fileId'] = updateData['file']?.id;
									data.push(updateData);
								} else {
									data.push({ id: null, ...d.data });
								}
							}
						}
					});
				}

				const payload = {
					actionname: 'EWM_EVALUATION_SHEET_SAVE',
					data: { data: data },
				};

				const res = await this.CALL_EWM_API(payload);
				e.component.cancelEditData();
				if (isSuccess(res)) {
					this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
					this.$refs.evaluationSheetGrid.getGridInstance.refresh();
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			}
		},
		/** @description : 삭제 */
		async onDeleteData() {
			const selectedRowsData = this.$refs.evaluationSheetGrid.selectedRowsData;

			const existUseFl = selectedRowsData.findIndex(element => element.useFl === this.$_enums.common.stringUsedFlag.YES.value);

			if (existUseFl > -1) {
				this.$_Msg(`사용중인 평가가 있습니다. '${selectedRowsData[existUseFl].sheetNm}' 평가표를 확인하세요.`);
				return;
			}

			const sendData = selectedRowsData.map(x => x.id);

			if (!selectedRowsData?.length) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			} else {
				if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
					return;
				}

				const payload = {
					actionname: 'EWM_EVALUATION_SHEET_DELETE',
					data: sendData,
					loading: true,
				};

				const res = await this.CALL_EWM_API(payload);

				if (isSuccess(res)) {
					this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
					this.$refs.evaluationSheetGrid.getGridInstance.refresh();
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			}
		},
		/** @description : 평가표구성 페이지 이동 */
		onUpdateData(data) {
			let params = { sheetId: data.id, sheetNm: data.sheetNm, useFl: data.useFl };
			this.$store.commit('setDetailParams', params);
			this.$router.push({ path: '/ewm/evaluation/setting/sheet/register' });
		},
		/** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
		setCustomTypes(key, value) {
			//this.searchType.customTypes[key] = value;
		},
		/** @description : 데이터 검색 메서드 */
		async selectDataList() {
			this.dataGrid.dataSource = new CustomStore({
				key: 'id',
				async load(loadOptions) {
					const payload = {
						actionname: 'EWM_EVALUATION_SHEET_LIST',
						loading: false,
					};

					const res = await vm.CALL_EWM_API(payload);
					if (isSuccess(res)) {
						const rtnData = {
							data: res.data.data,
							totalCount: res.data.header.totalCount,
						};

						vm.$refs.evaluationSheetGrid.totalCount = rtnData.totalCount;
						return rtnData;
					}
				},
				insert(values) {
					//return false;
				},
				update(key, values) {
					//return false;
				},
				totalCount: opts => {
					//데이터 없는 경우 totalCount 에러 발생하여 추가
					return new Promise((resolve, reject) => {
						resolve(0);
					});
				},
				/* remove(key) {
					return false;
				}, */
			});
		},
		/** @description : 셀렉트 박스 초기화 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			vm = this;

			this.initCodeMap().then(() => {
				this.dataGridLoopup.evalDivisionParentCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2);
				this.dataGridLoopup.evalDivisionCd.dataSource = options => {
					return {
						store: this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_eval_division']),
						filter: options.data ? ['parentId', '=', options.data.evalDivisionParentCd] : null,
					};
				};

				this.$refs.evaluationSheetGrid.getGridInstance.columnOption(
					'evalDivisionParentCd',
					'lookup',
					this.dataGridLoopup.evalDivisionParentCd,
				);
				this.$refs.evaluationSheetGrid.getGridInstance.columnOption('evalDivisionCd', 'lookup', this.dataGridLoopup.evalDivisionCd);
			});
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {},
	},
	created() {
		this.createdData();
		this.selectDataList();
	},
	mounted() {
		this.mountData();
	},
};
</script>

<style scoped>
.page_search_box .inner div {
	display: inline-block;
}
.page_search_box .inner > div:not(.box-btn-search) {
	vertical-align: middle;
	margin-right: 10px;
}
.page_search_box .spec-type-box {
	display: inline-block;
}
.page_search_box .inner .box-btn-search {
	margin-right: 15px;
}
.page_search_box #searchTooltip {
	margin-right: 5px;
	font-size: 22px;
	color: #808080;
}
</style>
<style></style>
